<script setup>

const nuxtApp = useNuxtApp()
const url = useRequestURL()

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const reseter = ref(null)

const bea = ref(null)
const about = ref(null)
const roadmap = ref(null)
const pricelist = ref(null)
const references = ref(null)
const team = ref(null)

nuxtApp.$listen('slideToSection', (section) => {
	eval(section)?.value?.scrollIntoView({ behavior: 'smooth' })
})

nuxtApp.hook('page:loading:end', () => {
	setTimeout(() => {
		nuxtApp.$event('pageLoaded', true)
	}, 500)
});

const resetBea = () => {
	nuxtApp.$event('resetBea')
}

useHeadData({
  title: 'BE-A: Dokonalá humanoidní recepční pro hoteliéry i hosty',
  description: 'Jsem BE-A, dokonalá AI recepční. Mluvím všemi světovými jazyky, nikdy nespím, vždy se usmívám a na e-mail odpovím do 2 minut.',
	url: url.href,
	og: {
		description: 'Jsem BE-A, dokonalá AI recepční. Mluvím všemi světovými jazyky, nikdy nespím, vždy se usmívám a na e-mail odpovím do 2 minut.',
		image: 'https://be-a.cz/img/OG.png',
		url: url.href,
		title: 'BE-A: Dokonalá humanoidní recepční pro hoteliéry i hosty',
	},
	twitter: {
		image: 'https://be-a.cz/img/OG.png',
		description: 'Jsem BE-A, dokonalá AI recepční. Mluvím všemi světovými jazyky, nikdy nespím, vždy se usmívám a na e-mail odpovím do 2 minut.',
		url: url.href,
		title: 'BE-A: Dokonalá humanoidní recepční pro hoteliéry i hosty',
	}
})

onMounted(() => {
	gsap.to(reseter.value, {
		scrollTrigger: {
			trigger: reseter.value,
			start: 'top top',
			onEnter: () => resetBea(),
		},
	})
})

</script>

<template>
	<div>
		<div ref="bea">
			<BEA />
		</div>

		<div class="text-center mt-16 mb-20" ref="reseter">
			<Button class="inline-block no-arrow-mobile">{{ $t('general.arrange-demo') }}</Button>
		</div>

		<div ref="about">
			<!-- <Video class="mb-20 md:mb-24 mt-24" /> -->
		</div>
		<AIReception class="mb-20 md:mb-24 mt-24" />
		<!-- <Numbers class="mb-20 md:mb-32" /> -->
		<Newsletter class="mb-14 md:mb-32" />
		<div ref="roadmap">
			<RoadMap class="mb-14 md:mb-32" />
		</div>
		<div ref="pricelist">
			<Pricelist class="mb-20 md:mb-24" />
		</div>
		<div ref="references">
			<Hoteliers class="mb-14 md:mb-32" />
		</div>
		<div ref="team">
			<People class="mb-14 md:mb-32" />
		</div>
	</div>
</template>